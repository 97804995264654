.footer {
    background-color: #0f5807;
    color: white;
    text-align: center;
    left: 0;
    bottom: 0;
    width: 100%;
}

.footer-table {
  margin-left: auto;
  margin-right: auto;
  width: 60%; /* Adjust this value as needed */
  max-width: 800px; /* Optional: Set a maximum width */
  border-collapse: collapse;
}

.footer-heading {
    text-align: center;
}

.footer-body {
    padding-left: 15%;
    vertical-align: top;
    text-align: left; /* Align text to the left */
}

.footer-body p {
    margin-block: 10px;
}

.footer-image {
    width: 30px; /* Adjust the size as needed */
    height: auto;
    margin-top: -5px;
    vertical-align: middle; /* Aligns the image with the text */
  }
  