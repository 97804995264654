@font-face {
  font-family: 'Sukhumvit';
  src: local('Sukhumvit'), url('./assets/fonts/SukhumvitSet-Text.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

.body,
.MuiTypography-body1,
.MuiTypography-h5,
.MuiTypography-h6,
.MuiTypography-root,
.MuiSelect-select.MuiSelect-select,
.MuiMenuItem-root,
.MuiButton-label, .MuiTab-wrapper, .MuiTab-wrapper, .MuiFormLabel-root, #root {
  font-family: 'Sukhumvit', sans-serif !important;
}

body {
  margin: 0;
  font-family: 'Sukhumvit', sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* background: url('assets/bg.jpg') no-repeat center center fixed;
  background-size: cover; */
  /* display: none; */
}

.patientRatio {
  font-size: 0.8rem !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.trends-widget {
  width: 100%;      
  max-width: 600px;
  height: 400px;
  margin: 20px auto;
  border: 1px solid #ccc;
}

.chartjs-render-monitor {
  background-color: white;
}

#epidemicReportTable {
  width: 100%;
  border-collapse: collapse;
  background-color: white;
}

#epidemicReportTable th, #epidemicReportTable td {
  border: 1px solid #ddd;
  text-align: left;
  padding: 8px;
}

#epidemicReportTable th {
  background-color: #f2f2f2;
}

/* Make the first two rows in thead sticky */
/* #epidemicReportTable thead tr th {
  position: sticky;
  top: 0;
  background-color: #f2f2f2;
  z-index: 3;
} */

thead tr:first-child th {
  position: sticky;
  top: 0;
  z-index: 10; /* Higher z-index */
  background-color: white; /* Or any solid color */
}

/* Apply to the second row <th> elements */
thead tr:nth-child(2) th {
  position: sticky;
  top: 38.5px; /* Replace [HEIGHT_OF_FIRST_ROW] with the actual height of the first row */
  z-index: 5;
  background-color: white; /* Or any solid color */
}

#epidemicReportTable thead tr:nth-child(2) th:not(:first-child) {
  cursor: pointer;
}

/* Make the first column in thead and tbody sticky */
#epidemicReportTable tbody tr th:first-child,
#epidemicReportTable tbody tr td:first-child {
  position: sticky;
  left: 0;
  z-index: 2;
  min-width: 150px;
  background-color: white;
}

#epidemicReportTable tbody tr td:first-child.red {
  background: #a30707;
}

#epidemicReportTable tbody tr td:nth-child(n+2) {
  text-align: right;
}


.MuiTypography-h5 {
  font-size: 1.2rem !important;
}

.MuiTypography-h6 {
  font-size: 1rem !important;
}

.MuiTypography-body1 {
  font-size: 0.8rem !important;
}

.lightgrey { background-color: '#888'; color: white;}
.green { background-color: #30bf30; color: white;}
.yellow { background-color: #ffe414; color: black;}
.orange { background-color: rgb(204, 133, 0); color: white;}
.red { background-color: #a30707; color: white;}
.expandbg { background-color: lightblue !important;}

* {
  scrollbar-width: thin; /* 'auto' or 'thin' */
  scrollbar-color: #888 #e1e1e1; /* Thumb and track color */
}

::-webkit-scrollbar {
  width: 6px; /* Width of the vertical scrollbar */
  height: 6px; /* Height of the horizontal scrollbar */
}

::-webkit-scrollbar-thumb {
  background: #888; /* Color and style of the scrollbar's thumb */
  border-radius: 10px; /* Roundness of the scrollbar's thumb */
}

::-webkit-scrollbar-thumb:hover {
  background: #555; /* Color of the scrollbar's thumb on hover */
}

.loading-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent black background */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999; /* Ensure it's above all other content */
}

.sortBtn {
  background: none;
  border: none;
  color: inherit;
  padding: 10px;
  cursor: pointer;
  outline: none;
};

.spinner {
  border: 5px solid #f3f3f3; /* Light grey border */
  border-top: 5px solid #3498db; /* Blue top border for the spinner */
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.map-chart-and-pie-chart {
  display: flex;
  flex-direction: row;
  min-height: 400px;
  width: 100%;
  overflow-x: auto;
  justify-content: center;
}

@media screen and (max-width: 1280px) {
  .map-chart-and-pie-chart {
    flex-direction: column;
  }
}
